<template>
    <el-container class="page-container">
        <!-- 搜索栏 -->
        <el-main class="page-main" style="padding: 0 20px">
            <br>
            <el-form label-width="80px" label-position='left' :model="query" ref="searchForm">
                <el-row :gutter="20">
                    <!--<el-col :xs="12" :sm="12" :md="8" :lg="6">-->
                    <!--<el-form-item label="用户电话" prop="mobile">-->
                    <!--<el-input v-model="query.mobile" size="medium" class="inner-input"></el-input>-->
                    <!--</el-form-item>-->
                    <!--</el-col>-->
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item label="活动名称" prop="title">
                            <el-input v-model="query.title" size="medium" class="inner-input"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item label="活动时间" prop="dateArr">
                            <el-date-picker class="inner-input"
                                            size="medium"
                                            v-model="query.dateArr"
                                            type="daterange"
                                            range-separator="至"
                                            :default-time="['00:00:00', '23:59:59']"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期"
                                            value-format="timestamp">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-button
                                style="vertical-align: top"
                                type="primary"
                                size="medium"
                                @click="handleSearch">搜索
                        </el-button>
                        <el-button
                                style="vertical-align: top"
                                type="primary"
                                size="medium"
                                @click="clearSearch">清除
                        </el-button>
                    </el-col>
                </el-row>
            </el-form>
            <div>
                <el-button type="primary" size="small" @click="handleOpenActive">新增</el-button>
                <el-button type="danger" size="small" :disabled="!multipleSelection.length" @click="handleDelete">删除活动
                </el-button>
            </div>
            <br>
            <el-table
                    :data="userList.list"
                    tooltip-effect="light"
                    border
                    stripe
                    style="width: 100%"
                    @selection-change="handleSelectionChange">
                <el-table-column
                        type="selection"
                        width="55">
                </el-table-column>
                <el-table-column
                        prop="id"
                        label="ID">
                </el-table-column>

                <el-table-column
                        prop="title"
                        label="活动名称">
                </el-table-column>
                <el-table-column
                        label="活动封面图">
                    <template slot-scope="scope">
                        <el-image
                                style="width: 48px; height: 48px"
                                :src="scope.row.picUrl"
                                :preview-src-list="[scope.row.picUrl]">
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="type"
                        label="活动类型"
                        :formatter="fomartType">
                </el-table-column>
                <el-table-column
                        prop="startTime"
                        label="开始时间">
                </el-table-column>
                <el-table-column
                        prop="endTime"
                        label="结束时间">
                </el-table-column>
                <el-table-column
                        prop="description"
                        label="活动描述">
                </el-table-column>

                <el-table-column
                        label="活动状态">
                    <template slot-scope="scope">
                        <el-tag :type="formatStatus(scope.row.state).type" size="medium">
                            {{formatStatus(scope.row.state).text}}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        width="120px"
                        fixed="right"
                        label="操作">
                    <template slot-scope="scope">
                        <el-dropdown>
                            <el-button type="primary" size="small">
                                更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-if="scope.row.state !==-1">
                                    <el-button type="primary" size="small" @click="handleChangeState(scope.row)">
                                        修改状态
                                    </el-button>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <el-button type="primary" size="small" @click="handleOpenEditActive(scope.row)">
                                        活动修改
                                    </el-button>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <el-button type="primary" size="small" @click="handleOpenActivePrize(scope.row)">
                                        奖品管理
                                    </el-button>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
            <br>
            <!-- 分页 -->
            <el-pagination

                    @current-change="handleCurrentChange"
                    @size-change="handleSizeChange"
                    :current-page="query.pageNumber"
                    :page-sizes="pageSizes"
                    :page-size="query.pageSize"
                    layout="total,sizes,prev, pager, next, jumper"
                    :total="userList.totalCount">
            </el-pagination>
        </el-main>
        <!--//编辑奖品-->
        <form-dialog @close="handleClose('addPrize')" title="活动奖品" :dialogVisible="prizeDialog" fullscreen>
            <template slot="content">
                <el-form ref="addPrize" label-position="right" :model="prizeData" :rules="rules" label-width="85px">
                    <div>
                        <el-button type="primary" size="small" @click="editPrizeDialog=true">新增</el-button>
                        <el-button type="danger" :disabled="!prizeSelections.length" size="small"
                                   @click="handleDetelPrizes">删除
                        </el-button>
                    </div>
                    <br>
                    <el-table
                            border
                            :data="prizeData.data"
                            style="width: 100%"
                            @selection-change="handleDetelColletion">
                        <el-table-column
                                type="selection"
                                width="55"></el-table-column>
                        <el-table-column
                                prop="name"
                                label="奖品名称"
                        >
                        </el-table-column>
                        <el-table-column
                                :formatter="formatPrize"
                                label="奖品"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="number"
                                label="奖品数量"
                        >
                        </el-table-column>

                        <el-table-column
                                prop="lastNum"
                                label="奖品剩余数量"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="probability"
                                label="中奖几率"
                        >
                        </el-table-column>
                        <el-table-column
                                label="图片">
                            <template slot-scope="scope">
                                <el-image
                                        style="width: 48px; height: 48px"
                                        :src="scope.row.picUrl"
                                        :preview-src-list="[scope.row.picUrl]">
                                </el-image>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="description"
                                label="描述"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="orderIndex"
                                label="排序"
                        >
                        </el-table-column>
                        <el-table-column
                                label="操作"
                        >
                            <template slot-scope="scope">
                                <el-button type="primary" size="mini" @click="handleOpenPrizeDialog(scope.row)">修改
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <br>
                    <div class="flex flex-direction-row-reverse btn-group">
                        <el-button type="primary" @click="handleClose('addPrize')" style="margin-left: 10px">确 定
                        </el-button>
                        <el-button @click="handleClose('addPrize')">取 消</el-button>
                    </div>
                </el-form>
            </template>

        </form-dialog>
        <!--//修改奖品-->
        <form-dialog @close="handleClosePrizDialog('editPrize')" :title="editTitle" :dialogVisible="editPrizeDialog">
            <template slot="content">
                <el-form ref="editPrize" label-position="right" :model="prizeFormData"
                         label-width="85px">
                    <el-form-item label="奖品名称" prop="name">
                        <el-input v-model="prizeFormData.name" class="inner-input" placeholder="请输入活动名称"></el-input>
                    </el-form-item>

                    <el-form-item label="奖品图片" prop="picUrl">
                        <label class="avatar-uploader" @change="handlePrizePicChange">
                            <input type="file" style="display: none;visibility: hidden" accept="image/*">
                            <input type="hidden" v-model="prizeFormData.picUrl">
                            <img v-if="prizeFormData.picUrl" :src="prizeFormData.picUrl" class="avatar">
                            <i v-else class="cw-icon cw-icon-tupian"></i>
                        </label>
                    </el-form-item>


                    <el-form-item label="奖品数量" prop="number">
                        <el-input v-model="prizeFormData.number" class="inner-input"
                                  placeholder="请输入奖品数量"></el-input>
                    </el-form-item>



                    <el-form-item label="是否为默认奖品" prop="isThanks">
                        <el-select placeholder="请选择类型" class="inner-input" v-model="prizeFormData.isThanks">
                            <el-option
                                    v-for="item in prizeTypes"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="prizeFormData.isThanks===1" label="优惠券" prop="couponId">
                        <el-select v-model="prizeFormData.couponId" filterable
                                   remote
                                   reserve-keyword
                                   placeholder="请输入关键词"
                                   :remote-method="getTicket" class="inner-input">
                            <el-option
                                    v-for="item in ticketList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="奖品描述" prop="description">
                        <el-input v-model="prizeFormData.description" class="inner-input"
                                  placeholder="请输入奖品描述"></el-input>
                    </el-form-item>
                    <el-form-item label="中奖几率" prop="probability">
                        <el-input v-model="prizeFormData.probability" class="inner-input"
                                  placeholder="请输入中奖几率"></el-input>
                    </el-form-item>
                    <el-form-item label="奖品权重" prop="orderIndex">
                        <el-input v-model="prizeFormData.orderIndex" class="inner-input"
                                  placeholder="请输入奖品权重"></el-input>
                    </el-form-item>
                    <div class="flex flex-direction-row-reverse btn-group">
                        <el-button type="primary" @click="handleEditPrize" style="margin-left: 10px">确 定</el-button>
                        <el-button @click="handleClosePrizDialog('editPrize')">取 消</el-button>
                    </div>
                </el-form>
            </template>
        </form-dialog>

        <!--//编辑活动-->
        <form-dialog @close="handleClose('addAction')" :title="editTitle" :dialogVisible="activeDialog">
            <template slot="content">
                <el-form ref="addAction" label-position="right" :model="activeFormData" :rules="rules"
                         label-width="85px">
                    <el-form-item label="活动名称" prop="title">
                        <el-input v-model="activeFormData.title" class="inner-input" placeholder="请输入活动名称"></el-input>
                    </el-form-item>

                    <el-form-item label="封面图" prop="picUrl">
                        <label class="avatar-uploader" @change="handleAvatarChange">
                            <input type="file" style="display: none;visibility: hidden" accept="image/*">
                            <input type="hidden" v-model="activeFormData.picUrl">
                            <img v-if="activeFormData.picUrl" :src="activeFormData.picUrl" class="avatar">
                            <i v-else class="cw-icon cw-icon-tupian"></i>
                        </label>
                    </el-form-item>

                    <el-form-item label="活动描述" prop="description">
                        <el-input v-model="activeFormData.description" class="inner-input"
                                  placeholder="请输入活动描述"></el-input>
                    </el-form-item>
                    <el-form-item label="活动时间" prop="date">
                        <el-date-picker size="medium"
                                        class="inner-input"
                                        :default-time="['00:00:00', '23:59:59']"
                                        v-model="activeFormData.date"
                                        type="daterange"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="活动类型" prop="type">
                        <el-select v-model="activeFormData.type" placeholder="请选择">
                            <el-option label="抽奖" :value="1"></el-option>
                            <el-option label="分享" :value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="活动状态" prop="state">
                        <el-select v-model="activeFormData.state" placeholder="请选择">
                            <el-option
                                    v-for="item in statusOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="活动周期" prop="period">
                        <el-select v-model="activeFormData.period" placeholder="请选择">
                            <el-option
                                    v-for="item in periodOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <div class="flex flex-direction-row-reverse btn-group">
                        <el-button type="primary" @click="handleAddActive" style="margin-left: 10px">确 定</el-button>
                        <el-button @click="handleClose('addAction')">取 消</el-button>
                    </div>
                </el-form>
            </template>
        </form-dialog>

    </el-container>

</template>

<script>
    import formDialog from '../../components/formDialog'
    import {getCouponsList} from "../../api/ticketManage";
    import * as activeApi from '../../api/activeManage';
    import {mapGetters} from 'vuex'

    const statusOptions = [
        {value: 0, label: '未开始'},
        {value: 1, label: '进行中'},
        {value: 2, label: '暂停'},
        {value: -1, label: '过期'},
    ]
    const periodOptions = [
        {value: 0, label: '不限次'},
        {value: 1, label: '一次性'},
        {value: 2, label: '一天一次'},
        {value: 3, label: '一周一次'},
        {value: 4, label: '一月一次'},
    ]
    const acitveTypes = [
        {
            label: '抽奖', value: 1
        },
        {
            label: '分享', value: 2
        }
    ]
    const prizeTypes = [
        {
            label: '奖品', value: 1
        },
        {
            label: '谢谢惠顾', value: 2
        }
    ]
    export default {
        components: {formDialog},
        data() {
            return {
                prizeTypes,
                baseUrl: process.env.VUE_APP_BASEAPI + 'open/file/upload/image',
                couponListArr: [],
                userList: {
                    list: []
                },
                info: {},
                loading: true,
                // 分页
                pageSizes: [10, 20, 30, 40],
                query: {
                    pageSizes: 10, // 分码
                    pageNumber: 1, // 每页条数
                    title: '', // 用户昵称
                    dateArr: [],
                },
                isSubmit: false,
                acitveTypes,
                prizeDialog: false,
                activeDialog: false,
                editPrizeDialog: false,
                rules: {
                    title: [
                        {required: true, message: '请输入活动名称！', trigger: 'change'},
                    ],
                    picUrl: [
                        {required: true, message: '请上传活动封面图！', trigger: 'change'},
                    ],
                    description: [
                        {required: true, message: '请输入活动描述！', trigger: 'change'},
                    ],
                    date: [
                        {required: true, message: '请选择活动日期！', trigger: 'change'},
                    ],
                    state: [
                        {required: true, message: '请选择活动状态！', trigger: 'change'},
                    ],
                    period: [
                        {required: true, message: '请选择活动周期！', trigger: 'change'},
                    ],
                    type: [
                        {required: true, message: '请选择活动类型！', trigger: 'change'},
                    ],
                },
                prizeData: {
                    data: []
                },
                activeFormData: {
                    title: '',
                    picUrl: '',
                    description: '',
                    date: null,
                    state: 1,
                    period: 1,
                    type: 1
                },
                editTitle: '新增活动',
                statusOptions,
                periodOptions,
                multipleSelection: [],
                prizeFormData: {
                    name: '',
                    picUrl: '',
                    number: '',
                    couponId: '',
                    description: '',
                    probability: '',
                    orderIndex: '',
                    isThanks:2
                },
                prizeSelections: [],
                ticketList: [],
                selectAction: {}
            };
        },
        computed: {
            ...mapGetters(['token']),
        },
        methods: {
            //编辑奖品
            handleEditPrize() {
                this.$refs['editPrize'].validate(async valid => {
                    console.log(valid)
                    if (valid) {
                        let formData = {}
                        for (let i in this.prizeFormData) {
                            if (i !== 'coupon' && i !== 'activity' && i !== 'opBy' && i !== 'opAt' && i !== 'delFlag') {
                                formData[i] = this.prizeFormData[i]
                            }
                        }
                        try {
                            if (this.prizeFormData.id) { // 编辑数据
                                formData.id = this.prizeFormData.id;
                                await activeApi.editActivePrize(formData);
                                this.$message({
                                    type: 'success',
                                    message: '修改成功!'
                                });
                            } else { // 添加数据
                                formData.actId = this.selectAction.id
                                await activeApi.addDoList(formData);
                                this.$message({
                                    type: 'success',
                                    message: '新增成功!'
                                });
                            }
                            this.handleClosePrizDialog('editPrize')
                            let activeId = this.selectAction.id
                            let {data} = await activeApi.getActivedPrize({actId: activeId})
                            this.prizeData.data = data
                        } catch (e) {
                            console.log(e);
                            this.isSubmit = false;
                        }
                    }
                });
            },
            //关闭奖品编辑弹窗
            handleClosePrizDialog(form) {
                console.log(this.$refs[form])
                this.$refs[form].resetFields();
                this.editPrizeDialog = false
            },
            handleOpenPrizeDialog(row) {
                this.editTitle = '修改奖品'
                if (row.couponId && row.couponId != -1) {
                    this.ticketList.unshift(row.coupon)
                }
                this.prizeFormData = Object.assign({}, row)
                this.editPrizeDialog = true
            },
            async handleDetelPrizes() {
                try {
                    await this.$confirm('此操作将删除选中奖品', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })

                    let ids = []
                    this.prizeSelections.map(item => {
                        ids.push(item.id)
                    })
                    let activeId = this.selectAction.id
                    await activeApi.detelPrize({ids: ids.toString()})
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    let {data} = await activeApi.getActivedPrize({actId: activeId})
                    this.prizeData.data = data
                } catch (e) {
                    console.log(e)
                }
            },
            async handleChangeState(row) {
                try {
                    await this.$confirm('确定修改活动状态', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })

                    let formData = {
                        id: row.id,
                        state: row.state === 1 ? 2 : 1
                    }
                    await activeApi.editState(formData)
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                    this.handleSearch();
                } catch (e) {

                }

            },
            async handlePrizePicChange(e) {
                try {
                    let file = e.target.files[0];
                    let form = new FormData();
                    form.append('filedata', file);
                    let {data} = await activeApi.uploadImg(form)
                    this.prizeFormData.picUrl = data.url
                } catch (e) {
                }
            },
            formatPrize(row, column, cellValue, index) {
                if (row.couponId && row.couponId != -1) {
                    return row.coupon.name
                } else {
                    return '--'
                }
            },
            handleDetelColletion(val) {
                this.prizeSelections = val
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            async handleDelete() {
                try {
                    await this.$confirm('此操作将删除选中活动', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })

                    let ids = []
                    this.multipleSelection.map(item => {
                        ids.push(item.id)
                    })
                    await activeApi.deleteActive({ids: ids.toString()})
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.handleSearch();
                } catch (e) {
                    console.log(e)
                }
            },
            handleOpenActive(row) {
                this.activeDialog = true
            },
            handleOpenEditActive(row) {
                this.editTitle = '修改活动'
                row.date = [new Date(row.startTime), new Date(row.endTime)]
                this.activeFormData = Object.assign({}, row)
                this.activeDialog = true
            },
            handleAddActive() {
                this.$refs['addAction'].validate(async valid => {
                    console.log(valid)
                    if (valid) {
                        // this.isSubmit = true;
                        if (this.activeFormData.date && this.activeFormData.date.length) {
                            this.activeFormData.startTime = this.activeFormData.date[0].getTime()
                            this.activeFormData.endTime = this.activeFormData.date[1].getTime()
                        }
                        let formData = {}
                        for (let i in this.activeFormData) {
                            if (i !== 'date' && i !== 'foodSite') {
                                formData[i] = this.activeFormData[i]
                            }
                        }
                        try {
                            if (this.activeFormData.id) { // 编辑数据
                                formData.id = this.activeFormData.id;
                                await activeApi.editDo(formData);
                                this.$message({
                                    type: 'success',
                                    message: '修改成功!'
                                });
                            } else { // 添加数据
                                await activeApi.addActive(formData);
                                this.$message({
                                    type: 'success',
                                    message: '新增成功!'
                                });
                            }
                            this.isSubmit = false;
                            this.handleClose('addAction')
                            this.handleSearch()
                        } catch (e) {
                            console.log(e);
                            this.isSubmit = false;
                        }
                    }
                });

            },
            async handleAvatarChange(e) {
                try {
                    let file = e.target.files[0];
                    let form = new FormData();
                    form.append('filedata', file);
                    let {data} = await activeApi.uploadImg(form)
                    this.activeFormData.picUrl = data.url
                    // this.$forceUpdate();
                    console.log(data)
                } catch (e) {
                }
            },
            handleClose(form) {
                this.$refs[form].resetFields();
                this.prizeDialog = false
                this.activeDialog = false
                this.editPrizeDialog = false
            },
            fomartType(row, column, cellValue, index) {
                let type = this.acitveTypes.find(i => i.value === row.type)
                return type ? type.label : '--'
            },
            formatStatus(val) {
                switch (val) {
                    case 0:
                        return {text: '未开始', type: 'primary'}
                    case 1:
                        return {text: '进行中', type: 'success'};
                    case 2:
                        return {text: '暂停', type: 'danger'};
                    case -1:
                        return {text: '已过期', type: 'info'};
                    default:
                        return {}
                }
            },
            async handleSearch() {
                this.query.pageNumber = 1;
                this.getActiveLists();
            },
            clearSearch() {
                this.$refs.searchForm.resetFields();
                this.handleSearch()
            },
            handleCencle(formName) {
                this.$refs[formName].resetFields();
                this.dialogVisible = false;
            },
            // 分页
            handleSizeChange(val) {
                this.query.pageSizes = val;
                this.query.pageNumber = 1;
                this.getActiveLists();
            },
            handleCurrentChange(val) {
                this.query.pageNumber = val;
                this.getActiveLists();
            },
            async getActiveLists() {
                if (this.query.dateArr && this.query.dateArr.length) {
                    this.query.startTime = this.query.dateArr[0];
                    this.query.endTime = this.query.dateArr[1];
                } else {
                    this.query.startTime = '';
                    this.query.endTime = '';
                }
                let formData = {}
                for (let i in this.query) {
                    if (this.query[i] && i !== 'dateArr') {
                        formData[i] = this.query[i]
                    }
                }
                let {data} = await activeApi.getActiveList(formData);
                this.userList = data;
            },
            async handleOpenActivePrize(row) {
                try {
                    this.selectAction = row
                    let {data} = await activeApi.getActivedPrize({actId: row.id})
                    this.prizeDialog = true
                    this.prizeData.data = data
                    console.log(data)
                } catch (e) {

                }
            },
            async getTicket(val) {
                let formData = {}
                if (val) {
                    formData.name = val
                } else {
                    formData = {}
                }
                let {data} = await getCouponsList(formData);
                console.log(data)
                this.ticketList = data.list;
            },
        },

        async mounted() {
            this.loading = false;
            this.getActiveLists();
        }

    };
</script>

<style scoped lang="less">
    .avatar-uploader {
        height: 70px;
        width: 70px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px dashed #dcdcdc;
        border-radius: 4px;
        &.el-upload:hover {
            border-color: #409EFF;
        }
        .cw-icon-tupian {
            font-size: 28px;
            color: #8c939d;
        }
        .el-upload {
            height: 100%;
            display: flex !important;
            justify-content: center;
            align-items: center;
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }
        .avatar {
            height: 100%;
            width: 100%;
            display: block;
        }
    }
</style>

